import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {



    // children: [
    // {
    path: '/',
    // name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },

  // ],
  // },


  {
    path: "/layout",
    name: "layout",
    component: () => import(/* webpackChunkName: "layout" */ '../views/layout.vue'),
    children: [
      {
        path: '/Dashboard',
        component: () => import(/* webpackChunkName: "home" */ '../views/dashboard.vue'),
      },
      {
        path: '/About',
        component: () => import(/* webpackChunkName: "orders" */ '../views/About.vue'),
      },
      {
        path: '/Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue'),
      },
      {
        path: '/Projects',
        component: () => import(/* webpackChunkName: "settings" */ '../views/projects.vue'),
      },
      {
        path: '/Others',
        component: () => import(/* webpackChunkName: "settings" */ '../views/others.vue'),
      },
      {
        path: '/Petition',
        component: () => import(/* webpackChunkName: "settings" */ '../views/petition.vue'),
      },
      {
        path: '/FollowUps',
        component: () => import(/* webpackChunkName: "settings" */ '../views/followups.vue'),
      },
      {
        path: '/CivilWorks',
        component: () => import(/* webpackChunkName: "settings" */ '../views/civilworks.vue'),
      },
      {
        path: '/Petitiondetail',
        component: () => import(/* webpackChunkName: "settings" */ '../views/petition-detail.vue'),
      },
      {
        path: '/Projectdetail',
        component: () => import(/* webpackChunkName: "settings" */ '../views/project-detail.vue'),
      },
      {
        path: '/Civilworkdetail',
        component: () => import(/* webpackChunkName: "settings" */ '../views/civilwork-detail.vue'),
      },
      {
        path: '/Followupdetail',
        component: () => import(/* webpackChunkName: "settings" */ '../views/followup-detail.vue'),
      },
      {
        path: '/Contacts',
        component: () => import(/* webpackChunkName: "settings" */ '../views/contacts.vue'),
      }
    ]
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
