<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
  }),
};
</script> 

<style >
@font-face {
  font-family: "Lato";
  src: local("fonts"), url(./assets/font/Lato-Regular.ttf);
}
* {
  font-family: "lato";
}
.fsize12 {
  font-size: 12px !important;
}
.fsize14 {
  font-size: 14px !important;

}
.fsize16{
  font-size: 16px !important;
}
.clr-000 {
  color: #000000 !important;
}

.marg-0 {
  margin: 0px !important;
}

.clr-1867c0 {
  color: #1867c0;
}

.fw-bold {
  font-weight: bold !important;
}
.padd-t-16 {
  padding-top: 16px;
}
.padd-b-16 {
  padding-bottom: 16px;
}
.padd-t-8 {
  padding-top: 8px;
}
.padd-b-8 {
  padding-bottom: 8px;
}
.padd-l-24 {
  padding-left: 24px;
}
.padd-r-24 {
  padding-right: 24px;
}
.marg-t-16 {
  margin-top: 16px;
}
.bgr_clr_f9f9f9 {
  background-color: #f9f9f9 !important;
}
.padd-t-24 {
  padding-top: 24px !important;
}
.padd-0-40 {
  padding: 0px 40px !important;
}
.h-40 {
  height: 40px !important;
}
.h-72 {
  height: 72px;
}
.h-68{
  height:68px;
}
.w-464 {
  width: 464px;
}
.border-input {
  border: solid 1px #a3a3a3;
  box-shadow: none !important;
}
.border_radius {
  border-radius: 4px !important;
}
/* .v-card  {
  min-height: 1308 !important;
} */
.wholeCard.v-card {
  min-height: 450px !important;
}
.padd-8-16 {
  padding: 8px 0px 8px 16px !important ;
}
.w-225 {
  width: 225px !important;
}
.marg-l-12 {
  margin-left: 12px !important;
}
.marg-l-16 {
  margin-left: 16px !important;
}
.fsize13 {
  font-size: 13px;
}
.lineBelowHead {
  border-top: 1px solid#f9f9f9;
}
.marg-t-32 {
  margin-top: 32px !important;
}
.clr_55585a {
  color: #55585a !important;
}
.w-225.v-input__control {
  width: 225px !important;
}
.w-464.v-input__control {
  width: 464px !important;
}
.v-input .v-label {
  font-size: 14px !important;
}
.v-messages {
  min-height: 0px !important;
}
.btn-clr {
  color: #007aff !important;
  border: 1px solid #007aff !important;
}
.padd-b-28 {
  padding-bottom: 28px;
}
/* .theme--light.v-btn.v-btn--has-bg {
  background-color: #fff !important;
} */
.textTransform_None {
  text-transform: none !important;
}
.margin_bottom-7rem {
  margin-bottom: 7rem !important;
}
.marg-t-20 {
  margin-top: 20px !important;
}
.marg-t-24 {
  margin-top: 24px !important;
}
.marg-t-34 {
  margin-top: 34px !important;
}
.marg-t-35 {
  margin-top: 35px !important;
}
.marg-t-32 {
  margin-top: 32px !important;
}
.marg-l-24 {
  margin-left: 24px !important;
}

.brl_clr_btn {
  border: 1px solid #2e3031 !important;
}
.bgr_clr_btn_save {
  background-color: #007aff !important;
  color: #fff !important;
  border: 1px solid #007aff !important;
}
.bgr_clr_btn_cancel {
  background-color: #fff !important;
}
.btn_width_96 {
  min-width: 96px !important;
}
.fsize20 {
  font-size: 20px !important;
}
.w-400 {
  width: 400px !important;
}
.min_width_48 {
  min-width: 48px !important;
}
.max_w-225 {
  max-width: 225px !important;
}
/* .v-btn--is-elevated  */

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: unset !important;
}
.outLine_none {
  outline: none !important;
}
.marg-l-10 {
  margin-left: 10px !important;
}
.marg-t-10 {
  margin-top: 10px !important;
}

.fright {
  float: right !important;
}
.marg-r-24 {
  margin-right: 24px !important;
}
.v-breadcrumbs {
  padding: 0px !important;
  font-size: 12px !important;
}
.text-danger {
  color: #ff0000 !important;
}
.is-invalid {
  border: 1px solid red !important;
}
.marg-t-4{
  margin-top: 4px !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.w-375{
  width: 375px !important;
}
.loader{
  position: absolute !important;
  left: 50% !important;
  top: 45% !important;
  z-index: 99 !important;
}
  
  @media (min-width: 1024px) and (max-width:1366px){
    .aligndiv{
    background-color: rgb(243, 246, 249);
    border-radius: 8px;
    padding-left: 1px;
    line-height: 36px;
    width: 300px;
    margin-left: 20%;
    max-width: 270px !important
    }
  }
    @media (min-width: 1366px) and (max-width:1700px){
    .aligndiv{
    background-color: rgb(243, 246, 249);
    border-radius: 8px;
    padding-left: 1px;
    line-height: 36px;
    width: 300px;
    margin-left: 8%;
    max-width: 270px !important
    }
  }
</style>